.app-root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.appBody {
    display: flex;
    flex: 1;
    flex-flow: column;
    min-height: 0;
    background-color: #edebe9d3;
    overflow: auto;
}

.login-spinner {
    align-self: center;
    flex: 1 0;
}

* {
    box-sizing: border-box;
}

html {
    font-family: 'Roboto', 'Segoe UI', sans-serif;
}