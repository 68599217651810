.meetingCardFree {
    border-left: 0.5rem solid #10990c;

    .cardSection {
        display: grid;
        grid-template: 1fr 1fr/auto auto 1fr;
        flex-flow: row;
        align-items: center;
    }

    .icon {
        grid-area: span 2;

        text-align: center;
        font-weight: 600;
        font-size: 0.9em;
        margin-right: 8px;
        width: 48px;
        height: 48px;
        line-height: 46px;
        border-radius: 50%;
        // background-color: rgb(0, 120, 212);
        background-color: #10990c;
        color: white;
    }



    .title {
        display: flex;
        margin-top: 0;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .time {
        margin-top: 0;
        color:#8a8886;
        font-size: 0.8rem;
        justify-self: flex-end;

    }

}