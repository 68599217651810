.home {
    display: flex;
    flex: 1;
    display: flex;
    flex-flow: column;
}


.cardList {
    display: flex;
    flex: 1 1;
    flex-flow: column;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 0;
    padding-bottom: 5rem;
}


.resourceHeader {
    background: white;
    padding: 1rem;
    display: flex;
    flex-flow: row;
}

.resourceName {
    font-size: 1.2rem;
    font-weight: 600;
    color: #323130;
}


.infoIcon {
    margin-left: auto;
}

.resourceIcon {
    margin-right: 8px;
    width: 32px;
}


