.scheduleDay {
    margin-top: 1rem;
}

.header {
    display:flex;
    align-items: center;
}
.date {
    margin-left: 1rem;
    font-weight: 700;
    font-size: 1rem;
}

.day {
    margin-left: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    color: #6264A7;
}

.noMeetingCard {
    border-left: 0.5rem solid #6264A780;
}

.noMeetings {
    flex: 1 1;
    color: #979593;
    text-align: left;
    font-size: 0.9rem;

}
