.meetingRow {
    flex: 1 1;
    display: grid;
    grid-template: auto auto / auto 1fr auto;
    align-items: center;
}

.organiser {
    grid-area: 1 / 1 / 3 / 2;
}

.title {
    flex: 1 1;
    grid-area: 1 / 2 / 2 / 3;
    margin-top: 0;
    align-items: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.location {
    grid-area: 2 / 2 / 3 / 4;
    
    text-align: left;
    padding-bottom: 0;
    overflow-wrap:break-word;
}

.time {
    margin-left: .25rem;
    margin-top: 0;
    white-space: nowrap;
    grid-area: 1 / 3 / 2 / 4;
}

.resourceIcon {
    width: 48px;
    height: 48px;
    margin-right: 8px;

}

