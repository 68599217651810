.actionButton {
    svg {
        height: 28px;
    }
}

.leaveMeeting {
    span {
        color: #cc0000;
    }

    svg {
        height: 28px;
        fill: #cc0000;
    }
}
