.ResourceInformation {
    display: flex;
    flex-flow: column;

    .actionButtons {
        display: flex;
        flex-flow: row;
        margin-top: 16px;
        padding-top: 8px;

        border-top: 1px solid #793b3a39;

    }

    .mapImage {
        width: 256px;
        height: 290px;
    }
}