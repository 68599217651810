.resourcePersona {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.15rem solid black;
}

.resourcePersonaIcon {
    width: 66.6%;
}
