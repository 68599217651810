.MeetingCard {
    border-left: 0.5rem solid #6264A7;

    button svg {
        height: 28px;
        fill: #566977c5;
    }

    .infoButton {
        margin-left: auto;
        svg { 
            height: 32px;
            width: 32px;
        }
    }

    button {
        display: flex;
        height: auto;
    }

    &.started {
        border-left: 0.5rem solid #d13438;        
    }

    &.pending {
        border-left-color: transparent;
        border-left: none;
        position: relative;
        &::before {
            content: '';
            background: url('../../icons/pending.svg');
            width: 0.5rem;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}